/* eslint-disable */
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useScroll, motion, useAnimation } from "framer-motion";
import PinkLogo from "../../src/Images/Transparent-Pink-Logo.png";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import MobileNavBar from "../Components/MobileNavBar";

function AboutScreen() {
  const { scrollYProgress } = useScroll();
  const controls = useAnimation();
  const carouselRef = useRef(null);
  const [lastKnownScrollY, setLastKnownScrollY] = useState(0);
  const [ticking, setTicking] = useState(false);

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);

    const handleScroll = () => {
      setLastKnownScrollY(window.scrollY);

      if (!ticking) {
        window.requestAnimationFrame(() => {
          doSomething(lastKnownScrollY);
          setTicking(false);
        });
        setTicking(true);
      }
    };

    const doSomething = (scrollY) => {
      if (carouselRef.current) {
        const rect = carouselRef.current.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom > 0) {
          // Element is in the viewport
          const scrollAmount = (rect.top / window.innerHeight) * 450;
          controls.start({ x: scrollAmount * 2 });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [ticking, controls]);
  return (
    <div className="bg-black min-h-screen">
      <motion.div
        style={{ scaleX: scrollYProgress }}
        className="fixed top-0 left-0 right-0 h-1 bg-[#FFD3E9]  origin-left z-10 "
      ></motion.div>
      <div className="w-full md:px-8 px-2 flex justify-between items-center  border-b-2 border-[#FFD3E9] border-opacity-10 pb-3 fixed top-1 bg-black z-10 md:visible invisible">
        <div className="">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="bg-[#FFD3E9] font-aktivXBold text-sm md:text-xl uppercase py-2 px-2 md:px-4 rounded-sm"
          >
            order now
          </motion.button>
        </div>
        <Link to={"/"}>
          <div>
            <img
              src={PinkLogo}
              alt="logo"
              className="md:w-[6rem] w-[3rem] ml-12"
            />
          </div>
        </Link>
        <div>
          <div className="flex flex-col items-end uppercase font-aktivXBold md:w-full w-[1-rem]">
            <h3 className="text-white text-xs md:text-lg text-right ">
              subscribe to stay updated
            </h3>
            <h3 className="text-white text-xs md:text-lg text-right">
              text JOIN to 844-336-9506
            </h3>
          </div>
          <div></div>
        </div>
      </div>

      <div className="md:invisible visible">
        <MobileNavBar />
      </div>

      <div className="md:pt-32 pt-44 flex flex-col items-center ">
        <div>
          <h2 className="text-[#FFD3E9] font-aktivBlack md:text-[4rem] text-[3rem] md:leading-normal leading-[3rem] text-center ">
            Meet The Owner, Shae Cole
          </h2>
        </div>
        <div className="md:w-2/4 w-full md:px-0 px-2  pt-7">
          {" "}
          <img
            src="https://voyagemia.com/wp-content/uploads/2022/03/c-PersonalShiffonColeman__65655AF2A78040F5BB2BBFE03B7B4385_1646266279008-e1646737595563-1000x600.jpeg"
            alt="owner"
          />
        </div>
        <div className="md:w-3/5 pt-12 leading-8 text-lg">
          <h3 className="text-[#FFD3E9] text-center font-aktivXBold uppercase md:text-[2rem] text-[2.5rem] md:mx-0 mx-2 md:leading-normal leading-[2.5rem]">
            The start of her journey
          </h3>
          <h4 className="text-white mt-4 md:text-left text-center md:px-0 px-4">
            Shae Cole comes from Jacksonville, Florida, and her passion for food
            has been evident from a young age, when she dreamt of opening her
            own restaurant. By 2015, she had begun drafting her very first food
            truck concept and menu. Though the initial direction evolved, by
            2020, during the peak of the pandemic, she found herself in a
            challenging position. She was breastfeeding her youngest son, Saint,
            who was less than a year old, and raising her four-year-old, Savion
            Sincere. All this while navigating a new job in a mortgage company,
            which was particularly trying as a single mother of two.{" "}
          </h4>

          <h4 className="text-white mt-4 md:text-left text-center md:px-0 px-4">
            However, her culinary drive never diminished. Selling food from her
            home on occasions like Taco Tuesdays and Soul Food Sundays, Shae
            took a leap of faith, leaving her job to fully commit to her food
            venture. Through selling plates, catering, and offering private chef
            services, "Sincere Saints Burgers and Shakes" was born just 11
            months later. The food truck has been operational just under 2
            years. and has seen encouraging success.
          </h4>

          <h4 className="text-white mt-4 md:text-left text-center md:px-0 px-4">
            Shae’s journey wasn’t without obstacles. The construction of her
            food trailer was delayed by two months because of the COVID
            pandemic. She had decided to oversee the purchase and installation
            of equipment personally, and encountered challenges in hiring prompt
            contractors. Instead of her target opening in March, she began
            operations in late June 2021. Moreover, within the initial month, a
            mishap resulted in a fire inside the trailer, causing minor damages.
          </h4>

          <h3 className="text-[#FFD3E9] mt-10 text-center font-aktivXBold uppercase md:text-[2rem] text-[2.5rem] md:mx-0 mx-2 md:leading-normal leading-[2.5rem]">
            One of the best trucks in florida
          </h3>
          <h4 className="text-white mt-4 md:text-left text-center md:px-0 px-4">
            Today, Sincere Saints Burgers and Shakes stands as one of the
            leading new food trucks in northeast Florida. They boast a
            mouth-watering range of burgers, imaginative milkshakes, and hearty
            loaded fries. Unlike many food trucks, Shae offers both savory and
            sweet delights, marking it as a one-stop culinary destination.
            Customers often laud its distinctiveness compared to other trucks in
            Jacksonville. With plans in motion to visit other Florida cities,
            the truck's mobility adds to its charm.
          </h4>

          <h3 className="text-[#FFD3E9] mt-10 text-center font-aktivXBold uppercase md:text-[2rem] text-[2.5rem] md:mx-0 mx-2 md:leading-normal leading-[2.5rem]">
            giving back to other upcoming Truckers
          </h3>
          <h4 className="text-white mt-4 md:text-left text-center md:px-0 px-4">
            Moreover, Shae established a Facebook group named "Female Food
            Truckers." She envisioned it as a sanctuary for women either already
            in or aspiring to join the food truck industry. It’s a platform
            where one can both offer and receive support, discuss business
            challenges without judgment, and gain encouragement. Within 2 and a
            half years, the group has grown exponentially, nearing 75,000 with
            the group.
          </h4>

          <h4 className="text-white mt-4 md:text-left text-center md:px-0 px-4">
            Shae believes in the power of networking and mentorship. She advises
            those entering the industry to explore Facebook groups specific to
            their field, asserting that these communities often hold a wealth of
            free information and potential mentors. She also underscores the
            value of joining local groups and attending events for offline
            networking opportunities.
          </h4>
        </div>
        <Link to={"/"}>
          <div className="flex items-center mt-4">
            <h4 className="text-[#FFD3E9] text-[1.9rem] font-aktivXBold uppercase">
              Go back
            </h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-6 h-6 text-[#FFD3E9] text-[3rem]"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
          </div>
        </Link>
      </div>
      <div className="mt-20">
        <Footer />
      </div>
    </div>
  );
}

export default AboutScreen;
