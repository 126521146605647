/* eslint-disable */
import React from "react";
import PinkLogo from "../../src/Images/Transparent-Pink-Logo.png";
import { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import { useEffect } from "react";
import axios from "axios";

const services = [
  {
    id: 1,
    service: "catering",
    description:
      "We can't wait to cater your upcoming event. Please fill out the short form below and we will get in contact with you promptly!",
    formType: "a",
  },
  {
    id: 2,
    service: "book the truck",
    description:
      "We'd love to bring our food truck out to your upcoming event. Just let us know the details and we'll get in contact with you asap!",
    formType: "a",
  },
  {
    id: 3,
    service: "something else",
    description:
      "Don't need catering or to get the food truck out, let us know what else you need help with. Just fill out the form bel",
    formType: "b",
  },
];

function CateringScreen() {
  const [service, setService] = useState(1);
  const [serviceName, setServiceName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [phone, setPhone] = useState("");
  const [guests, setGuests] = useState("");
  const [location, setLocation] = useState("");
  const [comments, setComments] = useState("");
  const [error, setError] = useState("");

  function handleService(serviceValue) {
    console.log(serviceValue);
    setService(serviceValue);
    if (serviceValue === 3) {
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setDate("");
      setGuests("");
      setLocation("");
      setComments("");
    }
  }

  async function handleSubmit(e) {
    console.log(service);
    e.preventDefault();

    let theServiceName;

    try {
      if (service === 1) {
        setServiceName("Catering");
        theServiceName = "Catering";
      }

      if (service === 2) {
        setServiceName("Truck");
        theServiceName = "Truck";
      }

      if (service === 3) {
        setServiceName("other");
        theServiceName = "other";
      }
    } catch (err) {
      console.log(err);
    }

    // if (
    //   (service === 1 || 2 && !serviceName),
    //   !firstName ||
    //     !lastName ||
    //     !email ||
    //     !date ||
    //     !phone ||
    //     !guests ||
    //     !location)
    // ) {
    //   setError("Please fill out all fields");
    //   return;
    // }

    try {
      const res = await axios.post(
        "https://sheet.best/api/sheets/d0296220-854e-4281-81e1-e0234e3c43dd",

        {
          Service: theServiceName,
          First: firstName,
          Last: lastName,
          Email: email,
          Phone: phone,
          Date: date,
          Guests: guests,
          Location: location,
          Comments: comments,
        },

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-black min-h-screen flex flex-col items-center pt-8 w-full ">
      <div className="w-[6rem] md:-ml-4 ">
        <Link to={"/"}>
          {" "}
          <img src={PinkLogo} alt="logo" />
        </Link>
      </div>
      <div className=" md:w-2/3  pb-12 ">
        <div className="flex md:flex-row flex-col justify-center items-center uppercase font-aktivXBold tracking-wider mt-8  text-2xl text-center">
          {services.map((s) => (
            <div key={s.id}>
              <div>
                {" "}
                <h1
                  className={
                    service === s.id
                      ? "bg-[#FFD3E9] px-8 text-black mx-4 md:my-0 my-4 text-center"
                      : "text-[#FFD3E9] px-8 hover:bg-[#FFD3E9] hover:text-black mx-4 md:my-0 my-4 text-center"
                  }
                  onClick={() => {
                    handleService(s.id);
                  }}
                >
                  {s.service}
                </h1>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center mt-8 ">
          {services.map((s) => (
            <div key={s.id}>
              {service === s.id && (
                <div className="flex flex-col items-center">
                  <h2 className="text-[#FFD3E9] md:text-[3.9rem] md:mt-0 -mt-3 text-[2.5rem] md:mx-0 mx-2 uppercase font-aktivBlack text-center">
                    {s.service}
                  </h2>
                  <h3 className="text-white md:text-[2.1rem] text-[1.4rem] leading[1.5rem] mx-6  md:leading-[3.1rem] font-extralight text-center">
                    {s.description}
                  </h3>

                  {s.formType === "a" ? (
                    <div className="border-[#FFD3E9] border-2 rounded-3xl md:px-12 px-8 py-8 md:mt-10 mx-4 mt-6 mb-10">
                      {/* //first level */}
                      <div className="flex mt-3 flex-wrap md:flex-nowrap ">
                        <div className="mx-3 md:w-1/2 w-full ">
                          {" "}
                          <h4 className="text-white capitalize pb-2 text-[1.2rem] font-aktivXBold">
                            first name
                          </h4>
                          <input
                            className="w-full py-2 px-4 rounded-md  bg-zinc-900 text-zinc-400 capitalize placeholder:uppercase placeholder:font-aktivXBold font placeholder:text-zinc-800"
                            placeholder="first name"
                            onChange={(e) => {
                              setFirstName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mx-3 md:w-1/2 w-full md:mt-0 mt-4">
                          {" "}
                          <h4 className="text-white capitalize pb-2 text-[1.2rem] font-aktivXBold">
                            last name
                          </h4>
                          <input
                            className=" w-full py-2 px-4 rounded-md bg-zinc-900  text-zinc-400 capitalize placeholder:uppercase placeholder:font-aktivXBold font placeholder:text-zinc-800"
                            placeholder="last name"
                            onChange={(e) => {
                              setLastName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      {/* second level */}
                      <div className="flex mt-3 flex-wrap md:flex-nowrap">
                        <div className="mx-3 md:w-1/2 w-full ">
                          {" "}
                          <h4 className="text-white capitalize pb-2 text-[1.2rem] font-aktivXBold">
                            email address
                          </h4>
                          <input
                            className="w-full py-2 px-4 rounded-md bg-zinc-900 text-zinc-400 capitalize placeholder:uppercase placeholder:font-aktivXBold font placeholder:text-zinc-800"
                            placeholder="email address"
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mx-3 md:w-1/2 w-full">
                          {" "}
                          <h4 className="text-white capitalize pb-2 text-[1.2rem] font-aktivXBold md:mt-0 mt-3">
                            phone number
                          </h4>
                          <input
                            className=" w-full py-2 px-4 rounded-md bg-zinc-900  text-zinc-400 capitalize placeholder:uppercase placeholder:font-aktivXBold font placeholder:text-zinc-800"
                            placeholder="phone number"
                            onChange={(e) => {
                              setPhone(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      {/* third level */}
                      <div className="flex mt-3 flex-wrap md:flex-nowrap">
                        <div className="mx-3 md:w-1/3 w-full">
                          {" "}
                          <h4 className="text-white capitalize pb-2 text-[1.2rem] font-aktivXBold md:mt-0 mt-3">
                            date of event
                          </h4>
                          <input
                            className="w-full py-2 px-4 rounded-md bg-zinc-900 text-zinc-400 capitalize placeholder:uppercase placeholder:font-aktivXBold font placeholder:text-zinc-800"
                            placeholder="date of event"
                            onChange={(e) => {
                              setDate(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mx-3 md:w-1/3 w-full">
                          {" "}
                          <h4 className="text-white capitalize pb-2 text-[1.2rem] font-aktivXBold md:mt-0 mt-3">
                            # of guests
                          </h4>
                          <input
                            className="w-full  py-2 px-4 rounded-md bg-zinc-900  text-zinc-400 capitalize placeholder:uppercase placeholder:font-aktivXBold font placeholder:text-zinc-800"
                            placeholder="# of guests"
                            onChange={(e) => {
                              setGuests(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mx-3 md:w-1/3 w-full">
                          {" "}
                          <h4 className="text-white capitalize pb-2 text-[1.2rem] font-aktivXBold md:mt-0 mt-3">
                            location
                          </h4>
                          <input
                            className=" w-full py-2 px-4 rounded-md bg-zinc-900  text-zinc-400 capitalize placeholder:uppercase placeholder:font-aktivXBold font placeholder:text-zinc-800"
                            placeholder="location"
                            onChange={(e) => {
                              setLocation(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex mt-3">
                        <div className="mx-3 w-full">
                          {" "}
                          <h4 className="text-white capitalize pb-2 text-[1.2rem] font-aktivXBold md:mt-0 mt-2">
                            additional comments
                          </h4>
                          <input
                            className="w-full py-2 px-4 pb-24 rounded-md bg-zinc-900 text-zinc-400 capitalize placeholder:uppercase placeholder:font-aktivXBold font placeholder:text-zinc-800"
                            placeholder="additional comments"
                            onChange={(e) => {
                              setComments(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                        className="flex flex-col items-center mt-6"
                      >
                        <button className="bg-[#FFD3E9] px-12 py-2 rounded-lg font-aktivBlack text-[2rem] uppercase ">
                          submit
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="border-[#FFD3E9] border-2 rounded-3xl md:px-12 px-8 py-8 md:mt-10 mx-4 mt-6 mb-10">
                      {/* //first level */}
                      <div className="flex mt-3 flex-wrap ">
                        <div className="mx-3  w-full ">
                          {" "}
                          <h4 className="text-white capitalize pb-2 text-[1.2rem] font-aktivXBold">
                            first name
                          </h4>
                          <input
                            className="w-full py-2 px-4 rounded-md  bg-zinc-900 text-zinc-400 capitalize placeholder:uppercase placeholder:font-aktivXBold font placeholder:text-zinc-800"
                            placeholder="first name"
                            onChange={(e) => {
                              setFirstName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mx-3  w-full mt-4">
                          {" "}
                          <h4 className="text-white capitalize pb-2 text-[1.2rem] font-aktivXBold">
                            last name
                          </h4>
                          <input
                            className=" w-full py-2 px-4 rounded-md bg-zinc-900  text-zinc-400 capitalize placeholder:uppercase placeholder:font-aktivXBold font placeholder:text-zinc-800"
                            placeholder="last name"
                            onChange={(e) => {
                              setLastName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      {/* second level */}
                      <div className="flex mt-3 flex-wrap">
                        <div className="mx-3  w-full ">
                          {" "}
                          <h4 className="text-white capitalize pb-2 text-[1.2rem] font-aktivXBold">
                            email address
                          </h4>
                          <input
                            className="w-full py-2 px-4 rounded-md bg-zinc-900 text-zinc-400 capitalize placeholder:uppercase placeholder:font-aktivXBold font placeholder:text-zinc-800"
                            placeholder="email address"
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mx-3  w-full">
                          {" "}
                          <h4 className="text-white capitalize pb-2 text-[1.2rem] font-aktivXBold  mt-4">
                            phone number
                          </h4>
                          <input
                            className=" w-full py-2 px-4 rounded-md bg-zinc-900  text-zinc-400 capitalize placeholder:uppercase placeholder:font-aktivXBold font placeholder:text-zinc-800"
                            placeholder="phone number"
                            onChange={(e) => {
                              setPhone(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex mt-3">
                        <div className="mx-3 w-full">
                          {" "}
                          <h4 className="text-white capitalize pb-2 text-[1.2rem] font-aktivXBold  mt-2">
                            additional comments
                          </h4>
                          <input
                            className="w-full py-2 px-4 pb-24 rounded-md bg-zinc-900 text-zinc-400 capitalize placeholder:uppercase placeholder:font-aktivXBold font placeholder:text-zinc-800"
                            placeholder="additional comments"
                            onChange={(e) => {
                              setComments(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="flex flex-col items-center mt-6"
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        <button className="bg-[#FFD3E9] px-12 py-2 rounded-lg font-aktivBlack text-[2rem] uppercase ">
                          submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}{" "}
            </div>
          ))}
        </div>
      </div>

      <div className="w-full pt-12">
        <Footer />
      </div>
    </div>
  );
}

export default CateringScreen;
