import NavBar from "./Components/NavBar";
import HeaderSection from "./Components/HeaderSection";
import ImageSection from "./Components/ImageSection";
import PromiseSection from "./Components/PromiseSection";
import TestimonialSection from "./Components/TestimonialSection";
import AboutSection from "./Components/AboutSection";
import CateringCTASection from "./Components/CateringCTASection";
import FAQSection from "./Components/FAQSection";
import Footer from "./Components/Footer";
import LocationSection from "./Components/LocationSection";
import { useEffect } from "react";
import MobileNavBar from "./Components/MobileNavBar";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-black min-h-screen overflow-x-hidden ">
      <div className="md:visible invisible">
        <NavBar />
      </div>
      <div className="md:invisible visible">
        <MobileNavBar />
      </div>
      <HeaderSection />
      <ImageSection />
      <PromiseSection />
      <TestimonialSection />
      <LocationSection id="location" />
      <AboutSection />
      <CateringCTASection />
      <FAQSection />
      <Footer />
    </div>
  );
}

export default App;
