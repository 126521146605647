import React from "react";
import PinkLogo from "../../src/Images/Transparent-Pink-Logo.png";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function NavBar() {
  return (
    <div className="w-full md:px-8 px-2 flex justify-between items-center pt-6 border-b-2 border-[#FFD3E9] border-opacity-10 pb-3 fixed top-0 bg-black z-10">
      <a href="https://sinceresaints.square.site/">
        <div>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="bg-[#FFD3E9] font-aktivXBold text-sm md:text-xl uppercase py-2 px-2 md:px-4 rounded-sm animate-pulse "
          >
            order now
          </motion.button>
        </div>
      </a>
      <Link to={"/"}>
        <div>
          <img
            src={PinkLogo}
            alt="logo"
            className="md:w-[6rem] w-[3rem] ml-12"
          />
        </div>
      </Link>
      <div>
        <div className="flex flex-col items-end uppercase font-aktivXBold md:w-full w-[1-rem]">
          <h3 className="text-white text-xs md:text-lg text-right ">
            subscribe to stay updated
          </h3>
          <h3 className="text-white text-xs md:text-lg text-right">
            text JOIN to 844-336-9506
          </h3>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default NavBar;
