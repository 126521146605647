import React from "react";
import { motion } from "framer-motion";
import { useScroll, useAnimation } from "framer-motion";
import { useEffect, useState, useRef } from "react";

function HeaderSection() {
  const { scrollYProgress } = useScroll();
  const controls = useAnimation();
  const carouselRef = useRef(null);
  const [lastKnownScrollY, setLastKnownScrollY] = useState(0);
  const [ticking, setTicking] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setLastKnownScrollY(window.scrollY);

      if (!ticking) {
        window.requestAnimationFrame(() => {
          doSomething(lastKnownScrollY);
          setTicking(false);
        });
        setTicking(true);
      }
    };

    const doSomething = (scrollY) => {
      if (carouselRef.current) {
        const rect = carouselRef.current.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom > 0) {
          // Element is in the viewport
          const scrollAmount = (rect.top / window.innerHeight) * 450;
          controls.start({ x: scrollAmount * 2 });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastKnownScrollY, ticking, controls]);

  return (
    <div className=" pb-32 flex flex-col justify-center items-center pt-[11.5rem] md:pt-[15.5rem]">
      <motion.div
        style={{ scaleX: scrollYProgress }}
        className="fixed top-0 left-0 right-0 h-1 bg-[#FFD3E9]  origin-left z-10 "
      ></motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 100 }}
        transition={{ duration: 1 }}
      >
        <h1 className="text-[#FFD3E9] text-[4rem] leading-[3.5rem] md:text-[5.5rem] text-center md:leading-[5.5rem] uppercase font-aktivBlack">
          amazing burgers,
          <br /> crazy shakes, <br /> & loaded fries{" "}
        </h1>
      </motion.div>
      <div className="flex justify-center items-center md:w-3/4 mt-3 pb-[2.5rem] md:pb-[5.5rem] md:flex-nowrap flex-wrap-reverse">
        <a href="https://sinceresaints.square.site">
          <motion.div
            initial={{ x: -200 }}
            whileInView={{ x: 0 }}
            transition={{ type: "spring", duration: 1 }}
            className=""
          >
            <button className="capitalize bg-slate-300 px-16 py-6 mx-3 rounded-full font-bold text-lg md:mt-0 mt-4">
              {" "}
              place an order
            </button>
          </motion.div>
        </a>
        <motion.div
          initial={{ x: 200 }}
          whileInView={{ x: 0 }}
          transition={{ type: "spring", duration: 0.5 }}
          className="md:w-1/2"
        >
          <h4 className="text-white font-thin px-4 text-xl leading-7 md:text-left text-center">
            Sincere Saint's is based on the Northside of Jacksonville, Florida.
            We are known for our burgers milkshakes, loaded fries, hotdogs, and
            more. Come find out why Sincere Saint's is one of Florida's favorite
            Food Trucks.{" "}
          </h4>
        </motion.div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 100 }}
        transition={{ duration: 1 }}
        className="grid grid-cols-4 md:gap-2 gap-1 justify-items-center text-white font-aktivXBold uppercase  border-t-2 border-b-2 border-[#FFD3E9] md:w-3/4 py-6 tracking-wider border-opacity-20  "
      >
        <div className="md:text-lg text-[.7rem]">🍔 burgers</div>

        <div className="md:text-lg text-[.7rem]">🍟 fries</div>
        <div className="md:text-lg text-[.7rem]">🥤 shakes</div>
        <div className="md:text-lg text-[.7rem]">➕ more</div>
      </motion.div>
    </div>
  );
}

export default HeaderSection;
