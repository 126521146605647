import React from "react";
import squareIcons from "../Icons/square icon sincere saints-04.png";
import arrowIcon from "../Icons/arrow icon-02-02.png";
import { motion } from "framer-motion";

function TestimonialSection() {
  return (
    <div className="flex flex-col items-center pt-20 md:pb-0 -mb-20 mx-2 md:mx-12">
      <div className="md:w-3/4 relative">
        <motion.div
          initial={{ y: -100, opacity: 0.5 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.1, ease: "easeIn", duration: 0.5 }}
          className="text-white absolute -top-8 font-aktivBlack -left-4 text-[4.8rem]"
        >
          "
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.3, ease: "easeIn", duration: 1 }}
          className="px-8"
        >
          <h1 className="text-[#FFD3E9] font-aktivBlack text-[2.4rem] md:text-[3.5rem] uppercase leading-[2.2rem] md:leading-[3.5rem]">
            <span className="text-white">the food was</span> really good{" "}
            <span className="text-white">& the shake was by far</span> the best
            i ever had.{" "}
            <span className="text-white">
              i love the creative art and food combination.
            </span>
          </h1>
          <h2 className="uppercase text-[1.5rem] tracking-widest text-white mt-2">
            rhishona gaines
          </h2>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.1, ease: "easeIn", duration: 1 }}
          className="top-0 right-0 absolute w-12"
        >
          <img src={squareIcons} alt="icon" />
        </motion.div>

        <div className="mt-12 flex items-center justify-center">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="-mt-12"
          >
            <img src={arrowIcon} alt="arrow icon" className="md:w-24 w-16" />
          </motion.div>
          <a href="https://sinceresaints.square.site/">
            <div className="md:px-12 md:mt-0 -mt-2 md:mx-0 mx-2">
              <motion.button
                whileHover={{ scale: 1.2 }}
                onHoverStart={(e) => {}}
                onHoverEnd={(e) => {}}
                className="bg-[#FFD3E9] md:mx-4 py-4 px-2 md:px-8 text-[1.6rem] md:text-[2.5rem] uppercase font-aktivBlack rounded-3xl"
              >
                place your order
              </motion.button>
            </div>
          </a>

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="-mt-12"
          >
            {" "}
            <img
              src={arrowIcon}
              alt="arrow icon"
              className="md:w-24 w-16 transform -scale-x-100"
            />
          </motion.div>
        </div>
        <div className="border-b-2 border-opacity-20 border-[#FFD3E9] mt-20"></div>
      </div>
    </div>
  );
}

export default TestimonialSection;
