import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function AboutSection() {
  return (
    <div className="flex pb-16  md:pb-32 flex-wrap-reverse md:mt-0 -mt-20">
      <div className="md:w-1/2 flex flex-col items-center">
        <motion.div
          initial={{ x: -300 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.7 }}
          className="md:mx-32 mx-4 "
        >
          <h3 className="text-[#FFD3E9] text-center text-[2rem] uppercase italic font-aktivXBold tracking-wider">
            discover
          </h3>
          <h2 className="text-white text-center font-aktivBlack uppercase text-[3.3rem] -mt-4">
            our story
          </h2>
          <div className="border-b-2 border-opacity-20 border-[#FFD3E9] "></div>

          <h4 className="text-white md:text-left text-center mt-4 font-extralight leading-8 text-[1.3rem] tracking-wide">
            Our founder, Shae Cole, comes from Jacksonville, Florida, and her
            passion for food has been evident from a young age, when she dreamt
            of opening her own restaurant. By 2015, she had begun drafting her
            very first food truck concept and menu. Though the initial direction
            evolved, by 2020, during the peak of the pandemic, she found herself
            in a challenging position...
          </h4>
          <Link to={"/about"}>
            <h3 className="text-[#FFD3E9] md:text-left text-center uppercase text-[1.5rem]  mt-2 italic font-bold tracking-wider">
              -read more
            </h3>
          </Link>
        </motion.div>
      </div>

      <motion.div
        initial={{ x: 300 }}
        whileInView={{ x: 0 }}
        transition={{ duration: 0.7 }}
        className="flex flex-col items-center"
      >
        <img
          src={
            "https://ik.imagekit.io/jsklle928/Sincere%20Saints/sincere%20saints%20family%20photo%20in%20front%20of%20truck.png?updatedAt=1693507438457"
          }
          alt="family"
          className="border-[.8rem] border-[#FFD3E9] m-4 md:w-auto w-[90%]"
        />
      </motion.div>
    </div>
  );
}

export default AboutSection;
