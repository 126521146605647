import React from "react";
import { motion } from "framer-motion";
import PinkLogo from "../Images/Transparent-Pink-Logo.png";
import { Link } from "react-router-dom";
import { useState } from "react";

function MobileNavBar() {
  const [open, setOpen] = useState(false);

  function handleOpen() {
    setOpen(!open);
    console.log(open);
  }
  return (
    <div>
      <div className="bg-[#FFD3E9] fixed top-0 w-full z-10">
        <h3 className="text-black text-md text-center py-1 capitalize font-bold  ">
          subscribe to stay updated: <br />
          text JOIN to 844-336-9506
        </h3>
      </div>
      <div className="w-full  px-4 flex items-center justify-center   pt-4 border-b-2 border-[#FFD3E9] border-opacity-10 pb-3 fixed top-14  bg-black z-10">
        <div className="w-1/3 flex flex-col items-start">
          <a href="https://sinceresaints.square.site/">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="bg-[#FFD3E9] font-aktivXBold text-sm md:text-xl uppercase py-2 px-2 md:px-4 rounded-sm animate-pulse"
            >
              order now
            </motion.button>
          </a>
        </div>

        <Link to={"/"} className="w-1/3 flex flex-col items-center">
          <div className="">
            <img src={PinkLogo} alt="logo" className="  w-[5rem] ml-2" />
          </div>
        </Link>

        {open ? (
          <motion.div className=" w-1/3  flex flex-col items-end">
            <motion.div
              onClick={handleOpen}
              initial={{ rotate: 0 }}
              animate={{ rotate: 180 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8 text-[#FFD3E9]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </motion.div>
          </motion.div>
        ) : (
          <motion.div
            className=" w-1/3  flex flex-col items-end"
            onClick={handleOpen}
          >
            <motion.div
              onClick={handleOpen}
              initial={{ rotate: 0 }}
              animate={{ rotate: -180 }}
            >
              <svg
                xmlns=" htp://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.7}
                stroke="currentColor"
                className="w-8 h-8 text-[#FFD3E9]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </motion.div>
          </motion.div>
        )}
      </div>
      <>
        {open && (
          <div className="bg-black h-[34rem]  mt-[9rem] w-full z-50  fixed overflow-hidden pb-12">
            <div className="text-white pt-4 flex flex-col items-center justify-center h-full font-aktivXBold uppercase space-y-4 text-[2rem] ">
              <a href="https://sinceresaints.square.site/">
                {" "}
                <h2
                  onClick={handleOpen}
                  className="hover:text-[2.2rem] hover:text-[#FFD3E9]"
                >
                  order
                </h2>
              </a>

              <Link to={"/catering"} onClick={handleOpen}>
                <h2 className="hover:text-[2.2rem] hover:text-[#FFD3E9]">
                  catering
                </h2>
              </Link>
              <Link to={"/about"} onClick={handleOpen}>
                <h2 className="hover:text-[2.2rem] hover:text-[#FFD3E9]">
                  about us
                </h2>
              </Link>

              <a
                onClick={handleOpen}
                href="https://www.facebook.com/sinceresaintsfoodtruck"
                target="blank"
              >
                {" "}
                <h2 className="hover:text-[2.2rem] hover:text-[#FFD3E9]">
                  facebook
                </h2>
              </a>

              <a
                href="https://www.instagram.com/sinceresaintsfoodtruck/"
                target="blank"
                onClick={handleOpen}
              >
                {" "}
                <h2 className="hover:text-[2.2rem] hover:text-[#FFD3E9]">
                  instagram
                </h2>
              </a>

              <Link to={"/catering"} onClick={handleOpen}>
                <h2 className="hover:text-[2.2rem] hover:text-[#FFD3E9]">
                  contact us
                </h2>
              </Link>
            </div>
          </div>
        )}
      </>
    </div>
  );
}

export default MobileNavBar;
