import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "../src/App";
import CateringScreen from "./Screens/CateringScreen";
import UpdateLocations from "./Screens/UpdateLocations";
import AboutScreen from "./Screens/AboutScreen";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/catering",
    element: <CateringScreen />,
  },
  {
    path: "/supersecret",
    element: <UpdateLocations />,
  },
  {
    path: "/about",
    element: <AboutScreen />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
