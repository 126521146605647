import React from "react";
import Logo from "../Images/white sincere saints logo-03 final-03.png";
import Facebook from "../Icons/facebook-02.png";
import Instagram from "../Icons/instagram-01.png";
import Mail from "../Icons/mail-03.png";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="  border-t-8 pt-16 pb-7 border-[#FFD3E9]">
      <div className="mx-12 flex md:flex-row flex-col   flex-wrap md:flex-no-wrap  justify-between items-center">
        <Link to={"/"}>
          <div className="md:w-32 w-24">
            <img src={Logo} alt="logo" />
          </div>
        </Link>
        <div className="grid grid-cols-3 justify-items-start gap-y-4 gap-x-8 mt-4  text-white ">
          <a href="https://sinceresaints.square.site">
            <h2 className="capitalize text-left pt-2 hover:text-[#FFD3E9] ">
              order now
            </h2>
          </a>
          <Link to={"/about"}>
            <h2 className="capitalize text-left pt-2 hover:text-[#FFD3E9]">
              about us
            </h2>
          </Link>
          <Link to={"/catering"}>
            <h2 className="capitalize text-left pt-2 hover:text-[#FFD3E9]">
              contact us
            </h2>
          </Link>

          <h2 className="text-left pt-2 hover:text-[#FFD3E9]">FAQs</h2>
          <a href="https://sinceresaints.square.site">
            <h2 className="capitalize text-left pt-2 hover:text-[#FFD3E9]">
              see menu
            </h2>
          </a>
        </div>
        <div className="flex items-center md:mt-0 mt-4  ">
          <a
            href="https://www.instagram.com/sinceresaintsfoodtruck/"
            target="blank"
          >
            <div className="w-8 px-1 hover:w-10">
              <img src={Instagram} alt="instagram" />
            </div>
          </a>
          <a
            href="https://www.facebook.com/sinceresaintsfoodtruck"
            target="blank"
          >
            <div className="w-8 px-1 hover:w-10">
              <img src={Facebook} alt="facebook" />
            </div>
          </a>
          <a href="mailto:SincereSaints@icloud.com">
            <div className="w-11 px-1 hover:w-12">
              <img src={Mail} alt="email" />
            </div>
          </a>
        </div>
      </div>
      <div className="text-white md:w-full flex px-12 justify-between text-[.8rem] mt-12 opacity-60">
        <h4>© Sincere Saints</h4>
        <h4>Privacy and Terms</h4>
        <a href="https://designcafe.co" target="blank">
          <h4 className="text-[#FFD3E9]">Site by DesignCafe</h4>
        </a>
      </div>
    </div>
  );
}

export default Footer;
