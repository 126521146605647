import React from "react";
import { useState } from "react";
import axios from "axios";

function UpdateLocations() {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  async function updateLocation(day) {
    try {
      const res = await axios.patch(
        `https://designcafe-client-backend-b4f24f3eb82a.herokuapp.com/api/locations/ssuid/${day}`,
        {
          name,
          address,
          date,
          time,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      window.location.reload();
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <div className="bg-black min-h-screen pt-8">
      <div>
        <h1 className="text-[#FFD3E9] font-aktivBlack text-[4rem] leading-[4rem] text-center">
          Update Location
        </h1>
      </div>
      <div className="flex flex-wrap">
        {/* Monday */}
        <div className="text-white p-8">
          <h2 className="text-[2rem]">Monday</h2>
          <div className="flex flex-col  w-96 ">
            <h3>name</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <h3>address</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
            <h3>date</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />
            <h3>time</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setTime(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col items-center">
            <button
              className="uppercase  text-[1rem] bg-[#FFD3E9] text-black font-black py-2 px-12 mt-4"
              onClick={() => {
                updateLocation("monday");
              }}
            >
              Update
            </button>
          </div>
        </div>

        {/* Tuesday */}
        <div className="text-white p-8">
          <h2 className="text-[2rem]">Tuesday</h2>
          <div className="flex flex-col  w-96 ">
            <h3>name</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <h3>address</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
            <h3>date</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />
            <h3>time</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setTime(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col items-center">
            <button
              onClick={() => {
                updateLocation("tuesday");
              }}
              className="uppercase  text-[1rem] bg-[#FFD3E9] text-black font-black py-2 px-12 mt-4"
            >
              Update
            </button>
          </div>
        </div>

        {/* Wednesday */}
        <div className="text-white p-8">
          <h2 className="text-[2rem]">Wednesday</h2>
          <div className="flex flex-col  w-96 ">
            <h3>name</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <h3>address</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
            <h3>date</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />
            <h3>time</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setTime(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col items-center">
            <button
              onClick={() => {
                updateLocation("wednesday");
              }}
              className="uppercase  text-[1rem] bg-[#FFD3E9] text-black font-black py-2 px-12 mt-4"
            >
              Update
            </button>
          </div>
        </div>

        {/* Thursday */}
        <div className="text-white p-8">
          <h2 className="text-[2rem]">Thursday</h2>
          <div className="flex flex-col  w-96 ">
            <h3>name</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <h3>address</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
            <h3>date</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />
            <h3>time</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setTime(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col items-center">
            <button
              onClick={() => {
                updateLocation("thursday");
              }}
              className="uppercase  text-[1rem] bg-[#FFD3E9] text-black font-black py-2 px-12 mt-4"
            >
              Update
            </button>
          </div>
        </div>

        {/* Friday */}
        <div className="text-white p-8">
          <h2 className="text-[2rem]">Friday</h2>
          <div className="flex flex-col  w-96 ">
            <h3>name</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <h3>address</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
            <h3>date</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />
            <h3>time</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setTime(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col items-center">
            <button
              onClick={() => {
                updateLocation("friday");
              }}
              className="uppercase  text-[1rem] bg-[#FFD3E9] text-black font-black py-2 px-12 mt-4"
            >
              Update
            </button>
          </div>
        </div>

        {/* Saturday */}
        <div className="text-white p-8">
          <h2 className="text-[2rem]">Saturday</h2>
          <div className="flex flex-col  w-96 ">
            <h3>name</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <h3>address</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
            <h3>date</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />
            <h3>time</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setTime(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col items-center">
            <button
              onClick={() => {
                updateLocation("saturday");
              }}
              className="uppercase  text-[1rem] bg-[#FFD3E9] text-black font-black py-2 px-12 mt-4"
            >
              Update
            </button>
          </div>
        </div>

        {/* Sunday */}
        <div className="text-white p-8">
          <h2 className="text-[2rem]">Sunday</h2>
          <div className="flex flex-col  w-96 ">
            <h3>name</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <h3>address</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
            <h3>date</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />
            <h3>time</h3>
            <input
              className="mt-2 text-black"
              onChange={(e) => {
                setTime(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col items-center">
            <button
              onClick={() => {
                updateLocation("sunday");
              }}
              className="uppercase  text-[1rem] bg-[#FFD3E9] text-black font-black py-2 px-12 mt-4"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateLocations;
