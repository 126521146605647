import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function CateringCTASection() {
  return (
    <div className="w-full flex flex-col items-center pb-18">
      <div className="bg-[#FFD3E9] flex py-3 justify-around text-xs md:text-3xl uppercase font-aktivBlack  w-full">
        <h4>burgers</h4>
        <h4>hotdogs</h4>

        <h4>fries</h4>
        <h4>milkshakes</h4>
        <h4>much more</h4>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.2, ease: "anticipate", duration: 1 }}
        className="md:w-3/4 md:mx-0 mx-1 flex flex-col items-center"
      >
        <h1 className="text-white pt-24 text-center font-aktivBlack tracking-wide text-[2.2rem] leading-[2.3rem] md:text-[3.3rem] md:leading-[3.6rem] uppercase">
          whether you want our
          <span className="text-[#FFD3E9]"> food truck</span> at your wedding,
          want us to cater your{" "}
          <span className="text-[#FFD3E9]">corporate event</span> or you want to{" "}
          <span className="text-[#FFD3E9]">share feedback</span>
        </h1>
        <Link to="/catering">
          <div className="bg-[#FFD3E9] w-56 py-2 px-3 rounded-full flex justify-around items-center mt-6 ">
            <button className="font-aktivXBold text-[1.3rem] uppercase">
              {" "}
              contact us
            </button>

            <div className="bg-white w-fit p-2 rounded-full border-[.2rem] border-black">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2.5}
                stroke="currentColor"
                className="w-6 h-6 text-black   "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 4.5l15 15m0 0V8.25m0 11.25H8.25"
                />
              </svg>
            </div>
          </div>
        </Link>
      </motion.div>
    </div>
  );
}

export default CateringCTASection;
