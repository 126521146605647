import React from "react";
import { motion } from "framer-motion";

function PromiseSection() {
  return (
    <div className="md:mt-0 -mt-8 ">
      <div className="flex flex-col items-center">
        <div>
          {" "}
          <motion.h1
            initial={{ x: -300 }}
            whileInView={{ x: 0 }}
            transition={{ type: "spring", duration: 2 }}
            className="text-[#FFD3E9] text-center font-aktivBlack text-[3rem]  md:text-[5rem] uppercase leading-[3rem] md:leading-[5rem] md:px-32"
          >
            we promise to leave you satisfied 😋
          </motion.h1>
          <motion.h2
            initial={{ x: 300 }}
            whileInView={{ x: 0 }}
            transition={{ type: "spring", duration: 2 }}
            className="text-white text-center px-2 py-3 text-2xl italic "
          >
            If you're in the Jacksonville area you HAVE TO come check out
            Sincere Saints!
          </motion.h2>
        </div>
        <motion.div
          initial={{ y: 200 }}
          whileInView={{ y: 0 }}
          transition={{ type: "spring", delay: 0.1, duration: 2 }}
          className="flex flex-col items-center pt-8 md:pt-12 pb-20"
        >
          <img
            src="https://ik.imagekit.io/jsklle928/Sincere%20Saints/sincere%20saints%20food%20truck.jpeg?updatedAt=1693494505701"
            alt="food truck"
            className="md:w-3/5"
          />
        </motion.div>
      </div>
      <div className="border-b-2 border-opacity-20 border-[#FFD3E9] md:mx-32"></div>
    </div>
  );
}

export default PromiseSection;
