import React from "react";
import { motion } from "framer-motion";

function ImageSection() {
  return (
    <div className="flex justify-center md:px-8 px-4 -mt-24 md:-mt-14 pb-16 flex-wrap md:flex-nowrap ">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 100 }}
        transition={{ delay: 0.2, duration: 2 }}
        className="md:p-8 md:pt-0"
      >
        <img
          src="https://ik.imagekit.io/jsklle928/Sincere%20Saints/burger%201%20sincere%20saints%20.png?updatedAt=1693493974801"
          alt="food"
          className="md:w-auto w-[100%]"
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 100 }}
        transition={{ delay: 0.2, duration: 2 }}
        className="md:p-8 md:pt-0 pt-6"
      >
        <img
          src="https://ik.imagekit.io/jsklle928/Sincere%20Saints/milkshake%20sincere%20saints.png?updatedAt=1693493774699"
          alt="food"
          className="md:w-auto w-[100%]"
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 100 }}
        transition={{ delay: 0.3, duration: 2 }}
        className="md:p-8 md:pt-0 pt-6 md:w-auto w-[100%]"
      >
        <img
          src="https://ik.imagekit.io/jsklle928/Sincere%20Saints/burger%202%20sincere%20saints.png?updatedAt=1693493936031"
          alt="food"
        />
      </motion.div>
    </div>
  );
}

export default ImageSection;
