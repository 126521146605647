/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";

function LocationSection() {
  const [monday, setMonday] = useState({});
  const [tuesday, setTuesday] = useState({});
  const [wednesday, setWednesday] = useState({});
  const [thursday, setThursday] = useState({});
  const [friday, setFriday] = useState({});
  const [saturday, setSaturday] = useState({});
  const [sunday, setSunday] = useState({});
  // const [locations, setLocations] = useState({});

  useEffect(() => {
    async function getLocations() {
      try {
        const res = await axios.get(
          `https://designcafe-client-backend-b4f24f3eb82a.herokuapp.com/api/locations/ssuid/`,
          { headers: { "Content-Type": "application/json" } }
        );
        console.log(res);

        const locationData = res.data.locations[0];

        setMonday(locationData.monday[0]);
        setTuesday(locationData.tuesday[0]);
        setWednesday(locationData.wednesday[0]);
        setThursday(locationData.thursday[0]);
        setFriday(locationData.friday[0]);
        setSaturday(locationData.saturday[0]);
        setSunday(locationData.sunday[0]);
        // setLocations(locationData);
      } catch (err) {
        console.log(err);
      }
    }

    getLocations();
  }, []);

  return (
    <div className="pb-32 md:pt-32 pt-40   " id="location">
      <motion.div
        initial={{ x: -300 }}
        whileInView={{ x: 0 }}
        transition={{ type: "spring", delay: 0.1, duration: 2 }}
      >
        <h1 className="md:text-[5rem] text-[3rem] mx-8 text-[#FFD3E9] leading-[3.7rem] uppercase text-center font-aktivBlack md:pb-4 underline underline-offset-8">
          "where are you today?"
        </h1>
      </motion.div>
      <div className="flex w-full text-white justify-center flex-wrap text-center">
        {monday && monday.name ? (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 1 }}
            className=" p-8 md:w-[20rem]  w-[100%]"
          >
            <h1 className="underline text-[#FFD3E9] font-aktivXBold text-[1.7rem]">
              Monday
            </h1>
            <h1 className="text-[2rem] font-aktivBlack -mt-1 uppercase">
              {monday.name}
            </h1>
            <h1 className="text-[1.5rem]  leading-7 capitalize italic">
              {monday.address}
            </h1>
            <h1 className="text-[1.4rem] mt-1 italic">{monday.date}</h1>
            <h1 className="text-[1.2rem] -mt-1 italic font-extralight ">
              {monday.time}
            </h1>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 1 }}
            className="p-8 md:w-[20rem] w-[100%]"
          >
            <h1 className="underline text-[#FFD3E9] font-aktivXBold text-[1.7rem]">
              Monday
            </h1>
            <h1 className="text-zinc-400 text-[1.5rem] md:tracking-normal tracking-wider font-aktivBlack">
              Closed
            </h1>
          </motion.div>
        )}

        {tuesday && tuesday.name ? (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 1 }}
            className="p-8 md:w-[20rem]  w-[100%]"
          >
            <h1 className="underline text-[#FFD3E9] font-aktivXBold text-[1.7rem]">
              Tuesday
            </h1>
            <h1 className="text-[2rem] font-aktivBlack -mt-1  uppercase">
              {tuesday.name}
            </h1>
            <h1 className="text-[1.5rem]  leading-7 capitalize italic">
              {tuesday.address}
            </h1>
            <h1 className="text-[1.4rem] mt-1  italic">{tuesday.date}</h1>
            <h1 className="text-[1.2rem] -mt-1 italic font-extralight ">
              {tuesday.time}
            </h1>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 1 }}
            className="p-8 md:w-[20rem] w-[100%]"
          >
            <h1 className="underline text-[#FFD3E9] font-aktivXBold text-[1.7rem]">
              Tuesday
            </h1>
            <h1 className="text-zinc-400 text-[1.5rem] md:tracking-normal tracking-wider font-aktivBlack">
              Closed
            </h1>
          </motion.div>
        )}

        {wednesday && wednesday.name ? (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 1 }}
            className="p-8  md:w-[20rem] w-[100%]"
          >
            <h1 className="underline text-[#FFD3E9] font-aktivXBold text-[1.7rem]">
              Wednesday
            </h1>
            <h1 className="text-[2rem] font-aktivBlack -mt-1 uppercase">
              {wednesday.name}
            </h1>
            <h1 className="text-[1.5rem]  leading-7 capitalize italic">
              {wednesday.address}
            </h1>
            <h1 className="text-[1.4rem] mt-1  italic">{wednesday.date}</h1>
            <h1 className="text-[1.2rem] -mt-1 italic font-extralight ">
              {wednesday.time}
            </h1>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 1 }}
            className="p-8 md:w-[20rem] w-[100%]"
          >
            <h1 className="underline text-[#FFD3E9] font-aktivXBold text-[1.7rem]">
              Wednesday
            </h1>
            <h1 className="text-zinc-400 text-[1.5rem] md:tracking-normal tracking-wider font-aktivBlack">
              Closed
            </h1>
          </motion.div>
        )}

        {thursday && thursday.name ? (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 1 }}
            className="p-8  md:w-[20rem]  w-[100%]"
          >
            <h1 className="underline text-[#FFD3E9] font-aktivXBold text-[1.7rem]">
              Thursday
            </h1>
            <h1 className="text-[2rem] font-aktivBlack -mt-1 uppercase">
              {thursday.name}
            </h1>
            <h1 className="text-[1.5rem]  leading-7 capitalize italic">
              {thursday.address}
            </h1>
            <h1 className="text-[1.4rem] mt-1  italic">{thursday.date}</h1>
            <h1 className="text-[1.2rem] -mt-1 italic font-extralight ">
              {thursday.time}
            </h1>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 1 }}
            className="p-8 md:w-[20rem]  w-[100%]"
          >
            <h1 className="underline text-[#FFD3E9] font-aktivXBold text-[1.7rem]">
              Thursday
            </h1>
            <h1 className="text-zinc-400 text-[1.5rem] md:tracking-normal tracking-wider font-aktivBlack">
              Closed
            </h1>
          </motion.div>
        )}

        {friday && friday.name ? (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 1 }}
            className="p-8  md:w-[20rem] w-[100%]"
          >
            <h1 className="underline text-[#FFD3E9] font-aktivXBold text-[1.7rem]">
              Friday
            </h1>
            <h1 className="text-[2rem] font-aktivBlack -mt-1 uppercase">
              {friday.name}
            </h1>
            <h1 className="text-[1.5rem]  leading-7 capitalize italic">
              {friday.address}
            </h1>
            <h1 className="text-[1.4rem] mt-1  italic">{friday.date}</h1>
            <h1 className="text-[1.2rem] -mt-1 italic font-extralight ">
              {friday.time}
            </h1>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 1 }}
            className="p-8 md:w-[20rem]  w-[100%]"
          >
            <h1 className="underline text-[#FFD3E9] font-aktivXBold text-[1.7rem]">
              Friday
            </h1>
            <h1 className="text-zinc-400 text-[1.5rem] md:tracking-normal tracking-wider font-aktivBlack">
              Closed
            </h1>
          </motion.div>
        )}

        {saturday && saturday.name ? (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 1 }}
            className="p-8  md:w-[20rem] w-[100%]"
          >
            <h1 className="underline text-[#FFD3E9] font-aktivXBold text-[1.7rem]">
              Saturday
            </h1>
            <h1 className="text-[2rem] font-aktivBlack -mt-1 uppercase">
              {saturday.name}
            </h1>
            <h1 className="text-[1.5rem] leading-7 capitalize italic">
              {saturday.address}
            </h1>
            <h1 className="text-[1.4rem] mt-1  italic">{saturday.date}</h1>
            <h1 className="text-[1.2rem] -mt-1 italic font-extralight ">
              {saturday.time}
            </h1>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 1 }}
            className="p-8 md:w-[20rem]  w-[100%]"
          >
            <h1 className="underline text-[#FFD3E9] font-aktivXBold text-[1.7rem]">
              Saturday
            </h1>
            <h1 className="text-zinc-400 text-[1.5rem] md:tracking-normal tracking-wider font-aktivBlack">
              Closed
            </h1>
          </motion.div>
        )}

        {sunday && sunday.name ? (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 1 }}
            className="p-8 md:w-[20rem] w-[100%]"
          >
            <h1 className="underline text-[#FFD3E9] font-aktivXBold text-[1.7rem]">
              Sunday
            </h1>
            <h1 className="text-[2rem] font-aktivBlack -mt-1 uppercase">
              {sunday.name}
            </h1>
            <h1 className="text-[1.5rem]  leading-7 capitalize italic">
              {sunday.address}
            </h1>
            <h1 className="text-[1.4rem] mt-1  italic">{sunday.date}</h1>
            <h1 className="text-[1.2rem] -mt-1 italic font-extralight ">
              {sunday.time}
            </h1>
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 1 }}
            className="p-8 md:w-[20rem]  w-[100%]"
          >
            <h1 className="underline text-[#FFD3E9] font-aktivXBold text-[1.7rem]">
              Sunday
            </h1>
            <h1 className="text-zinc-400 text-[1.5rem] md:tracking-normal tracking-wider font-aktivBlack">
              Closed
            </h1>
          </motion.div>
        )}
      </div>
    </div>
  );
}

export default LocationSection;
