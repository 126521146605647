import React from "react";
import { useState } from "react";

const qa = [
  {
    id: 1,
    question: "Where are you located?",
    answer:
      "We are a mobile vendor serving Jacksonville Florida and surrounding counties.",
  },
  {
    id: 2,
    question: "Can we find you on social media? ",
    answer:
      "Yes, follow us on Facebook, instagram, and TikTok @SincereSaintsFoodTruck",
  },
  {
    id: 3,
    question: "What forms of payment do you accept? ",
    answer:
      "We accept cash, all cards, tap pay, and cash app pay via our point of sale.",
  },
  {
    id: 4,
    question: "Do you have any vegan options? ",
    answer: "At this time we do not have any vegan specific offerings.",
  },
  {
    id: 5,
    question: " What type of hotdogs do you have?",
    answer: "We serve been hotdogs.",
  },
  {
    id: 6,
    question: "Are there any nuts in your milkshakes?",
    answer: "Nope! our milkshakes don't contain any nuts. ",
  },
];

function FAQSection() {
  const [showAnswer, setShowAnswer] = useState(0);
  const handleOpen = (value) => setShowAnswer(showAnswer === value ? 0 : value);

  return (
    <div className="flex flex-col items-center pb-24 px-4">
      <div className="border-b-2 border-opacity-20 border-[#FFD3E9] mt-20 w-3/4"></div>

      <div className="pt-12">
        <h2 className="text-[#FFD3E9] font-aktivBlack text-[5rem]">FAQs</h2>
      </div>
      <div>
        {qa.map((q) => (
          <div key={q.id} className="py-2 md:py-3">
            {" "}
            <div
              onClick={() => {
                handleOpen(q.id);
              }}
              className="flex justify-between md:items-center "
            >
              {" "}
              <h2 className=" text-white md:text-center text-left md:mr-0  text-[1.2rem] md:text-[1.6rem] tracking-wider">
                {q.question}
              </h2>
              <div className="">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className=" w-6 md:w-12 md:pl-4  text-[#FFD3E9]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </div>
            </div>
            {showAnswer === q.id && (
              <div>
                <h3 className="text-[#FFD3E9] text-left text-[.9rem] md:text-[1.4rem] pt-2 tracking-wider">
                  {q.answer}
                </h3>
                {q.id === 1 && (
                  <div className="  w-full flex flex-col items-center mt-2  capitalize font-bold hover:opacity-60">
                    <a href={"#location"}>
                      {" "}
                      <h4 className="bg-[#FFD3E9] px-12 rounded-md">
                        See our schedule here
                      </h4>
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQSection;
